import classNames from 'classnames';

import styles from './Input.module.scss';

const Input = (props) => {
  const { className, ...rest } = props;
  return <input {...rest} className={classNames(styles.input, className)} />;
};

export default Input;
