import { BASE_URL } from '../BASE_URL';

export const getSlider = async () => {
  const response = await fetch(BASE_URL + 'sliders/', {
    method: 'GET',
  });

  const data = await response.json();
  return data;
};
