import { useNavigate } from 'react-router-dom';

import { DecorIcon, IndustryIcon, LogoShortIcon } from 'src/assets/icons';
import Mission1 from 'src/assets/images/about//mission1.webp';
import { useWindowWidth } from 'src/hooks';

import styles from './Mission.module.scss';

const Mission = () => {
  const width = useWindowWidth();
  const navigate = useNavigate();

  return (
    <div className={styles.missionWrapper}>
      <DecorIcon className={styles.decor} />
      <DecorIcon className={styles.decor2} />
      <div className={styles.left}>
        <h2 className={styles.title}>НАША МИССИЯ</h2>
        <div className={styles.textBlock}>
          <p className={styles.text}>
            Это уникальная возможность пройти онлайн отборочный этап Конкурса, попасть на Межрегиональный Фестиваль моды
            и дизайна, получить финансовую поддержку, учиться у экспертов в индустрии, попасть на стажировку в компанию
            мечты и запустить собственный бизнес. Мы ищем перспективные кадры и проекты индустрии моды.
          </p>
          <p className={styles.boldText}>К участию приглашаются</p>
          <p className={styles.text}>
            Учащиеся и выпускники профильных образовательных организаций, дизайнеры и специалисты индустрии моды и
            креативных индустрий без профильного образования.
          </p>
        </div>
        {width <= 1100 && (
          <div className={styles.icons}>
            <a href="https://industry.art/" target="_blank">
              <IndustryIcon />
            </a>
            <a onClick={() => navigate('/')} target="_blank">
              <LogoShortIcon />
            </a>
          </div>
        )}
      </div>
      <div className={styles.right}>
        <img className={styles.image} src={Mission1} />
        {width > 1100 && (
          <div className={styles.icons}>
            <a href="https://industry.art/" target="_blank">
              <IndustryIcon />
            </a>
            <a onClick={() => navigate('/')} target="_blank">
              <LogoShortIcon />
            </a>
          </div>
        )}
      </div>
    </div>
  );
};

export default Mission;
