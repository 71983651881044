import { createAsyncThunk } from '@reduxjs/toolkit';

import { getNews } from 'src/API/requests';

const setNews = createAsyncThunk('news/setNews', async (options, thunkAPI) => {
  try {
    const response = await getNews(options);

    return response.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      return dateB - dateA;
    });
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export default setNews;
