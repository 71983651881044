import classNames from 'classnames';

import styles from './Button.module.scss';

const Button = (props) => {
  const { children, className, ...rest } = props;

  return (
    <button {...rest} className={classNames(styles.button, className)}>
      {children}
    </button>
  );
};

export default Button;
