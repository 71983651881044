import { BASE_URL } from '../BASE_URL';

export const subscribe = async (email) => {
  const response = await fetch(BASE_URL + 'subscribe/news/', {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json; charset=UTF-8',
    },
    body: JSON.stringify({
      email,
    }),
  });
  if (response.ok) {
    const data = await response.json();
    return data;
  } else {
    return response.status;
  }
};
