import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ArrowIcon, CloseIcon } from 'src/assets/icons';
import { useWindowWidth } from 'src/hooks';

import styles from './CustomSlider.module.scss';

const CustomSlider = ({ array, className, classNameImage, isShowModal, setShowModal }) => {
  const [index, setIndex] = useState(0);
  const [mouseStartPosition, setMouseStartPosition] = useState();
  const [mouseEndPosition, setMouseEndPosition] = useState();
  const [isShowNext, setIsShowNext] = useState(false);
  const [isShowPrev, setIsShowPrev] = useState(false);
  const [currentImg, setCurrentImg] = useState();

  const slider = useRef(null);
  const width = useWindowWidth();

  useEffect(() => {
    slider?.current.slickGoTo(0, true);

    setTimeout(() => {
      slider?.current.slickGoTo(0, true);
    }, 80);
  }, [array, slider]);

  const settings = {
    arrows: false,
    infinite: false,
    dots: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      setIndex(newIndex);
    },
    appendDots: (dots) => {
      return (
        <div>
          <ul className={styles.dots}>
            {dots.map((item) => {
              return (
                <li
                  style={{ width: `${50 / array.length}%` }}
                  className={classNames(styles.dot, {
                    [styles.dotActive]: item.props.children.props.children == index + 1,
                  })}
                  key={item.props.children.props.children}
                >
                  {item.props.children}
                </li>
              );
            })}
          </ul>
        </div>
      );
    },
  };

  useEffect(() => {
    if (!currentImg) return;
    const imgIndex = array.findIndex((item) => item.image == currentImg);
    if (imgIndex === 0 && array.length > 1) {
      setIsShowPrev(false);
      setIsShowNext(true);
    } else if (array.length === 1) {
      setIsShowPrev(false);
      setIsShowNext(false);
    } else if (imgIndex === array.length - 1) {
      setIsShowPrev(true);
      setIsShowNext(false);
    } else if (imgIndex === array.length - 1 && imgIndex === 0) {
      setIsShowPrev(false);
      setIsShowNext(false);
    } else {
      setIsShowPrev(true);
      setIsShowNext(true);
    }
  }, [currentImg]);

  const next = () => {
    const imgIndex = array.findIndex((item) => item.image == currentImg);
    if (array[imgIndex + 1]) setCurrentImg(array[imgIndex + 1].image);
  };

  const prev = () => {
    const imgIndex = array.findIndex((item) => item.image == currentImg);
    if (array[imgIndex - 1]) setCurrentImg(array[imgIndex - 1].image);
  };

  const openModal = (photo) => {
    if (mouseEndPosition.x == mouseStartPosition.x && mouseEndPosition.y == mouseStartPosition.y) {
      setShowModal(true);
      const image = array.filter((item) => item.image == photo)[0].image;
      setCurrentImg(image);
    }
  };

  return (
    <div className={classNames(styles.slider, className)}>
      {isShowModal && (
        <div className={styles.modalWrapper}>
          <CloseIcon className={styles.close} onClick={() => setShowModal(false)} />
          {isShowPrev && (
            <button className={styles.prevArrow} onClick={prev}>
              <ArrowIcon />
            </button>
          )}
          <img style={width > 768 ? { height: '100%' } : { width: '100%' }} src={currentImg} alt="image" />
          {isShowNext && (
            <button className={styles.nextArrow} onClick={next}>
              <ArrowIcon />
            </button>
          )}
        </div>
      )}
      {index != 0 && (
        <button className={styles.prevArrow} onClick={() => slider?.current.slickPrev()}>
          <ArrowIcon />
        </button>
      )}
      {index != array.length - 1 && (
        <button className={styles.nextArrow} onClick={() => slider?.current.slickNext()}>
          <ArrowIcon />
        </button>
      )}
      <Slider {...settings} ref={slider}>
        {array.map((image, id) => (
          <img
            onMouseDown={(e) => setMouseStartPosition({ x: e.clientX, y: e.clientY })}
            onMouseUp={(e) => setMouseEndPosition({ x: e.clientX, y: e.clientY })}
            onClick={() => openModal(image.image)}
            key={id}
            className={classNames(styles.galleryImage, classNameImage, { [styles.stretch]: image.stretch_image })}
            src={image.image}
          />
        ))}
      </Slider>
    </div>
  );
};

export default CustomSlider;
