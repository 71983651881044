import { animateScroll as scroll } from 'react-scroll';

import { LogoSecondIcon, TgIcon, UpIcon, VkIcon } from 'src/assets/icons';
import { useWindowWidth } from 'src/hooks';

import styles from './Footer.module.scss';

const Footer = () => {
  const width = useWindowWidth();

  const scrollToTop = () => {
    scroll.scrollToTop();
  };

  return (
    <footer className={styles.footer}>
      <div className={styles.content}>
        <div className={styles.logoWrapper}>
          <LogoSecondIcon className={styles.logo} />
          {width <= 1100 && (
            <div className={styles.social}>
              <a className={styles.icon} target="_blank" href="https://t.me/+faqgR6_qBkc5NmUy">
                <TgIcon />
              </a>
              <a className={styles.icon} target="_blank" href="https://vk.com/sibbrands">
                <VkIcon />
              </a>
            </div>
          )}
        </div>
        <address className={styles.address}>
          <a href="https://yandex.ru/maps/-/CDqZr-ye" className={styles.map}>
            Красной Армии 10/4, Красноярск
          </a>
          <a className={styles.tel} href="tel:+79135923844">
            +7 (913) 592-38-44
          </a>
          <a className={styles.email} href="mailto:info@sibbrands.ru">
            info@sibbrands.ru
          </a>
        </address>
        {width > 1100 ? (
          <div className={styles.social}>
            <a className={styles.icon} target="_blank" href="https://t.me/+faqgR6_qBkc5NmUy">
              <TgIcon />
            </a>
            <a className={styles.icon} target="_blank" href="https://vk.com/sibbrands">
              <VkIcon />
            </a>
          </div>
        ) : (
          <div className={styles.socialUp}>
            <UpIcon onClick={scrollToTop} />
          </div>
        )}
      </div>
    </footer>
  );
};

export default Footer;
