import { createAsyncThunk } from '@reduxjs/toolkit';

import { getSlider } from 'src/API/requests';

const setSlider = createAsyncThunk('slider/setSlider', async (options, thunkAPI) => {
  try {
    const response = await getSlider(options);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export default setSlider;
