import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { subscribe } from 'src/API/requests';
import { DecorIcon } from 'src/assets/icons';
import { Button, Footer, Header, Input, Modal } from 'src/components';
import { formateDate, useWindowWidth } from 'src/hooks';
import { setNews } from 'src/store/slices/actions';

import styles from './NewsPage.module.scss';

const NewsPage = () => {
  const [mouseStartPosition, setMouseStartPosition] = useState();
  const [mouseEndPosition, setMouseEndPosition] = useState();
  const [newsLength, setNewsLength] = useState();
  const [sliderIndex, setSliderIndex] = useState(1);
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const news = useSelector((state) => state.slice.news);

  const dispatch = useDispatch();
  const slider = useRef(null);
  const width = useWindowWidth();
  const navigate = useNavigate();

  useEffect(() => {
    if (!news) {
      dispatch(setNews());
    }
  }, []);

  useEffect(() => {
    let length = 0;
    if (news) {
      if (width > 1300) {
        length = Math.ceil(news.length / 3);
      } else if (width <= 1300) {
        length = Math.ceil(news.length / 2);
      }
    }

    setNewsLength([
      ...Array.from({ length }, (_, i) => {
        if (i >= 3 && i != length - 1) return '...';
        else if (i < length) return i + 1;
        else if (i == length - 1) return i + 1;
      }),
    ]);
  }, [news, width]);

  const settings = {
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const scrollSlider = (New) => {
    if (New == '...') return;
    setSliderIndex(New);
    if (width > 1300) {
      slider.current.slickGoTo((New - 1) * 3);
    } else if (width <= 1300) {
      slider.current.slickGoTo((New - 1) * 2);
    }

    let length = 0;
    if (news) {
      if (width > 1300) {
        length = Math.ceil(news.length / 3);
      } else if (width <= 1300) {
        length = Math.ceil(news.length / 2);
      }
    }

    setNewsLength([
      ...Array.from({ length }, (_, i) => {
        if (i > New + 1 || i < New - 3) {
          if (i != length - 1) {
            return '...';
          } else return i + 1;
        } else return i + 1;
      }),
    ]);
  };

  const handleClick = async () => {
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      const res = await subscribe(email);
      if (res != 400 && res != 409) {
        setIsOpen(true);
        setEmail('');
        setError(false);
      } else if (res == 409) {
        setIsOpen(true);
        setErrorMessage('Подписка с такой почтой уже существует');
        setEmail('');
      } else if (res == 400) {
        setEmail('');
        setError(true);
      }
    } else {
      setEmail('');
      setError(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key == 'Enter') {
      handleClick();
    }
  };

  return (
    <div className={styles.page}>
      <Helmet>
        <title>SibBrands - Новости</title>
        <meta name="description" content="Будь в курсе последних новостей Сибирских брендов" />
      </Helmet>
      {isOpen && (
        <Modal
          close={() => {
            setIsOpen(false);
            setErrorMessage('');
          }}
          errorMessage={errorMessage}
        />
      )}
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1>НОВОСТИ</h1>
          <DecorIcon />
          <DecorIcon />
          <DecorIcon />
          {width <= 1300 && (
            <Button onClick={() => navigate('/events')} className={styles.eventsButton}>
              Мероприятия
            </Button>
          )}
        </div>
        {news && (
          <div className={styles.news}>
            <Slider ref={slider} className={styles.slider} {...settings}>
              {news.map((New) => (
                <div
                  onMouseDown={(e) => setMouseStartPosition({ x: e.clientX, y: e.clientY })}
                  onMouseUp={(e) => setMouseEndPosition({ x: e.clientX, y: e.clientY })}
                  onClick={() => {
                    if (mouseEndPosition.x == mouseStartPosition.x && mouseEndPosition.y == mouseStartPosition.y) {
                      navigate(`/news/${New.id}`);
                    }
                  }}
                  key={New.id}
                  className={styles.new}
                >
                  <p className={styles.newDate}>{formateDate(New.date)}</p>
                  <img className={styles.newImage} src={New.cover} />
                  <p className={styles.newTitle}>{New.title}</p>
                  <p className={styles.newDesc}>{New.short_description}</p>
                </div>
              ))}
            </Slider>
          </div>
        )}
      </div>
      <div className={styles.buttons}>
        <div className={styles.left}>
          <div className={styles.wrap}>
            <p className={styles.subText}>Подписаться на новости</p>
            {width <= 600 && newsLength && (
              <p className={styles.pages}>
                {newsLength.map((New, id) => (
                  <span
                    className={classNames(
                      styles.span,
                      { [styles.spanDots]: New == '...' },
                      { [styles.activeSpan]: New == sliderIndex },
                    )}
                    onClick={() => scrollSlider(New)}
                    key={id}
                  >
                    {New}
                  </span>
                ))}
              </p>
            )}
          </div>
          <div className={styles.subWrapper}>
            <Input
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              type="email"
              placeholder={error ? 'Некорректный email' : 'email'}
              onKeyDown={handleKeyDown}
            />
            <Button onClick={handleClick}>Подписаться</Button>
          </div>
          <p className={styles.sendText}>
            Нажимая кнопку «Отправить», вы соглашаетесь на обработку персональных данных
          </p>
        </div>
        <div className={styles.right}>
          {width > 600 && newsLength && (
            <p className={styles.pages}>
              {newsLength.map((New, id) => (
                <span
                  className={classNames(
                    styles.span,
                    { [styles.spanDots]: New == '...' },
                    { [styles.activeSpan]: New == sliderIndex },
                  )}
                  onClick={() => scrollSlider(New)}
                  key={id}
                >
                  {New}
                </span>
              ))}
            </p>
          )}
          {width > 1300 && <Button onClick={() => navigate('/events')}>Мероприятия</Button>}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default NewsPage;
