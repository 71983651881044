import { createAsyncThunk } from '@reduxjs/toolkit';

import { getDesigners } from 'src/API/requests';

const setBrands = createAsyncThunk('brands/setBrands', async (options, thunkAPI) => {
  try {
    const response = await getDesigners(options);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export default setBrands;
