import { Fragment, useEffect, useState } from 'react';

import { DecorIcon } from 'src/assets/icons';
import { useWindowWidth } from 'src/hooks';

import styles from './RunningLine.module.scss';

const RunningLine = () => {
  const [line, setLine] = useState([]);

  const width = useWindowWidth();

  useEffect(() => {
    const tmp = [];
    const iterator = width > 1100 ? 12 : width > 600 ? 7 : 3;
    for (let i = 0; i < iterator; i++) {
      tmp.push(
        <>
          <DecorIcon className={styles.decor} />
          <p className={styles.text}>НОВОСТИ</p>
        </>,
      );
    }
    setLine(tmp);
  }, [width]);

  return (
    <div className={styles.runningLineWrapper}>
      <div className={styles.runningLine}>
        {/* <div className={styles.firstWrapper}>
          {line.map((line, id) => (
            <Fragment key={id}>{line}</Fragment>
          ))}
        </div>
        <div className={styles.secondWrapper}>
          {line.map((line, id) => (
            <Fragment key={id}>{line}</Fragment>
          ))}
        </div> */}
      </div>
    </div>
  );
};

export default RunningLine;
