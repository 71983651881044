import classNames from 'classnames';
import { useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { subscribe } from 'src/API/requests/';
import { Button, Input, Modal } from 'src/components';
import { formateDate, useWindowWidth } from 'src/hooks';

import styles from './NewsHeader.module.scss';

const NewsHeader = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const { favoriteNew, newsWithoutFavoriteNew: news } = useSelector((state) => state.slice);

  const width = useWindowWidth();
  const navigate = useNavigate();

  const handleClick = async () => {
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      const res = await subscribe(email);
      if (res != 400 && res != 409) {
        setIsOpen(true);
        setEmail('');
        setError(false);
      } else if (res == 409) {
        setIsOpen(true);
        setErrorMessage('Подписка с такой почтой уже существует');
        setEmail('');
      } else if (res == 400) {
        setEmail('');
        setError(true);
      }
    } else {
      setEmail('');
      setError(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key == 'Enter') {
      handleClick();
    }
  };
  return (
    <div className={classNames(styles.newsWrapper, { [styles.newsWrapper1100]: width <= 1100 })}>
      {isOpen && (
        <Modal
          close={() => {
            setIsOpen(false);
            setErrorMessage('');
          }}
          errorMessage={errorMessage}
        />
      )}
      <div className={styles.leftWrapper}>
        <div className={styles.prevNew}>
          {news && news[0] && (
            <div className={styles.textWrapper} onClick={() => navigate(`/news/${news[0].id}`)}>
              <p className={styles.date}>{formateDate(news[0].date)}</p>
              <p className={styles.desc}>{news[0].title}</p>
            </div>
          )}
          {width <= 1100 && <Button onClick={() => navigate('/news')}>Все новости</Button>}
        </div>
        <div className={styles.nowNewWrapper}>
          {favoriteNew && (
            <div className={styles.nowNew}>
              <div className={styles.leftBlock}>
                <p className={styles.dateNowNew}>{formateDate(favoriteNew.date)}</p>
                <p className={styles.descNowNew}>{favoriteNew.title}</p>
                <button onClick={() => navigate(`/news/${favoriteNew.id}`)} className={styles.button}>
                  Подробнее &#62;
                </button>
              </div>
              <img className={styles.image} src={favoriteNew.cover} />
            </div>
          )}
          {width > 1100 && (
            <div className={styles.rightWrapper}>
              <Button onClick={() => navigate('/news')}>Все новости</Button>
              <p className={styles.subText}>Подписаться на новости</p>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder={error ? 'Некорректный email' : 'email'}
                onKeyDown={handleKeyDown}
              />
              <Button onClick={handleClick}>Подписаться</Button>
              <p className={styles.sendText}>
                Нажимая кнопку «Отправить», вы соглашаетесь
                <br /> на обработку персональных данных
              </p>
            </div>
          )}
        </div>
        <div className={styles.nextNew}>
          {news && news[1] && (
            <div className={styles.textWrapper} onClick={() => navigate(`/news/${news[1].id}`)}>
              <p className={styles.date}>{formateDate(news[1].date)}</p>
              <p className={styles.desc}>{news[1].title}</p>
            </div>
          )}
          {width <= 1100 && (
            <div className={styles.buttonsWrapper}>
              <p className={styles.subText2}>Подписаться на новости</p>
              <div className={styles.buttons}>
                <Input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                  placeholder={error ? 'Некорректный email' : 'email'}
                  onKeyDown={handleKeyDown}
                />
                <Button onClick={handleClick}>Подписаться</Button>
              </div>
              <p className={styles.sendText}>
                Нажимая кнопку «Отправить», вы соглашаетесь на обработку персональных данных
              </p>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default NewsHeader;
