import { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';

import { Footer, Header, RunningLine } from 'src/components';
import { setSlider, setNews, setEvents, setBrands } from 'src/store/slices/actions';

import styles from './MainPage.module.scss';
import NewsHeader from './NewsHeader';

const MainPage = () => {
  const [hover, setHover] = useState(0);
  const sliders = useSelector((state) => state.slice.slider);

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setSlider());
    dispatch(setNews());
    dispatch(setEvents());
    dispatch(setBrands());
  }, []);

  const handleHover = (id) => {
    setHover(id);
  };

  return (
    <div className={styles.page}>
      <Helmet>
        <title>Сибирсие бренды</title>
        <meta name="description" content="Мы ищем перспективные кадры и проекты индустрии моды." />
      </Helmet>
      <Header />
      <div className={styles.banner}>
        <h1>Сибирсие бренды</h1>
        <p>
          Это уникальная возможность пройти онлайн отборочный этап Конкурса, попасть на Межрегиональный Фестиваль моды и
          дизайна, получить финансовую поддержку, учиться у экспертов в индустрии, попасть на стажировку в компанию
          мечты и запустить собственный бизнес. Мы ищем перспективные кадры и проекты индустрии моды.
        </p>
      </div>
      {sliders && (
        <div
          onMouseLeave={() => setHover(0)}
          className={styles.images}
          style={{
            gridTemplateColumns: `${sliders.map((slider, id) => `${hover == id + 1 ? '2fr' : '1fr'}`)}`.replace(
              /,/g,
              ' ',
            ),
          }}
        >
          {sliders.map((slider, id) => (
            <img onMouseEnter={() => handleHover(id + 1)} key={id} src={slider.image} className={styles.image} />
          ))}
        </div>
      )}
      <RunningLine />
      <NewsHeader />
      <Footer />
    </div>
  );
};

export default MainPage;
