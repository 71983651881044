import { Navigate, useLocation } from 'react-router-dom';

import { Footer, Header } from 'src/components';

import styles from './ConfirmPage.module.scss';

const ConfirmPage = () => {
  const { search } = useLocation();

  if (!search.includes('?email=')) {
    return <Navigate to={'/'} replace={true} />;
  }

  return (
    <div className={styles.page}>
      <Header />
      <h1 className={styles.title}>Вы успешно подписаны на новости sibbrands.ru</h1>
      <Footer />
    </div>
  );
};

export default ConfirmPage;
