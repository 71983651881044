const formateDate = (inputDate) => {
  const months = [
    'января',
    'февраля',
    'марта',
    'апреля',
    'мая',
    'июня',
    'июля',
    'августа',
    'сентября',
    'октября',
    'ноября',
    'декабря',
  ];

  if (!inputDate) return;

  const parts = inputDate.split('-');
  const day = parseInt(parts[2], 10);
  const month = months[parseInt(parts[1], 10) - 1];
  const year = parseInt(parts[0], 10);

  return `${day} ${month} ${year}`;
};

export default formateDate;
