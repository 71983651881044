import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getEvent } from 'src/API/requests';
import { DecorIcon } from 'src/assets/icons';
import Placeholder from 'src/assets/images/experts/placeholder.png';
import { Button, Footer, Header, CustomSlider } from 'src/components';
import { getDate, useWindowWidth } from 'src/hooks';

import styles from './EventPage.module.scss';

const EventPage = () => {
  const [event, setEvent] = useState();
  const [isShowModal, setShowModal] = useState(false);
  const events = useSelector((state) => state.slice.events);

  const { id } = useParams();
  const navigate = useNavigate();
  const width = useWindowWidth();

  useEffect(() => {
    if (events) {
      setEvent(events.find((event) => event.id == id));
    } else {
      getEvent(id).then((event) => setEvent(event));
    }
  }, []);

  return (
    <div className={styles.page}>
      <Header />
      {event && (
        <div className={styles.wrapper}>
          <DecorIcon className={styles.decor} />
          <div className={styles.wrapperInner}>
            <CustomSlider isShowModal={isShowModal} setShowModal={setShowModal} array={event.gallery} />
            <div className={styles.right}>
              {width > 1100 && (
                <div>
                  <p className={styles.name}>{event.title}</p>
                  <p className={styles.brand}>{getDate(event)}</p>
                </div>
              )}
              <div className={styles.about}>
                <Button onClick={() => navigate('/events')} className={styles.button}>
                  Назад
                </Button>
              </div>
            </div>
          </div>
          {width <= 1100 && (
            <div>
              <p className={styles.name}>{event.title}</p>
              <p className={styles.brand}>{getDate(event)}</p>
            </div>
          )}
          <p dangerouslySetInnerHTML={{ __html: event.description }} className={styles.text}></p>
          {event?.experts.length && <h2>Гости и эксперты недели моды "Сибирские бренды"</h2>}
          <div className={styles.expertWrapper}>
            <div className={styles.expert}>
              {event?.experts.map((item, index) => (
                <div key={index}>
                  {item.photo !== null ? (
                    <img src={item.photo}></img>
                  ) : (
                    <img className={styles.image} src={Placeholder}></img>
                  )}
                  <h3>{item.first_name}</h3>
                  <h3>{item.second_name}</h3>
                  <p>{item.short_description}</p>
                </div>
              ))}
            </div>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default EventPage;
