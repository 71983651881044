import classNames from 'classnames';
import { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';

import { BurgerIcon, LogoIcon, TgIcon, VkIcon } from 'src/assets/icons';
import { useWindowWidth } from 'src/hooks';

import BurgerMenu from '../BurgerMenu';

import styles from './Header.module.scss';

const Header = () => {
  const [isShowMenu, setShowMenu] = useState(false);

  const { pathname } = useLocation();
  const width = useWindowWidth();
  const navigate = useNavigate();

  return (
    <header className={styles.header}>
      <LogoIcon onClick={() => navigate('/')} className={styles.logo} />
      {width > 1100 && (
        <nav className={styles.nav}>
          <ul className={styles.navList}>
            <li>
              <NavLink
                className={classNames(styles.navLink, { [styles.navLinkActive]: pathname == '/about' })}
                to="/about"
              >
                О нас
              </NavLink>
            </li>
            <li>
              <NavLink
                className={classNames(styles.navLink, { [styles.navLinkActive]: pathname.includes('/brands') })}
                to="/brands"
              >
                Бренды
              </NavLink>
            </li>
            <li>
              <NavLink
                className={classNames(styles.navLink, { [styles.navLinkActive]: pathname.includes('/events') })}
                to="/events"
              >
                Мероприятия
              </NavLink>
            </li>
            <li>
              <NavLink
                className={classNames(styles.navLink, { [styles.navLinkActive]: pathname.includes('/news') })}
                to="/news"
              >
                Новости
              </NavLink>
            </li>
          </ul>
        </nav>
      )}
      <div className={styles.social}>
        {width > 550 ? (
          <>
            <a target="_blank" href="https://t.me/+faqgR6_qBkc5NmUy">
              <TgIcon />
            </a>
            <a target="_blank" href="https://vk.com/sibbrands">
              <VkIcon />
            </a>
          </>
        ) : (
          <BurgerIcon onClick={() => setShowMenu(!isShowMenu)} />
        )}
      </div>
      {width <= 1100 && width > 550 && (
        <nav className={styles.navTablet}>
          <NavLink className={styles.navLink} to="/">
            О нас
          </NavLink>
          <NavLink className={styles.navLink} to="/brands">
            Бренды
          </NavLink>
          <NavLink className={styles.navLink} to="/events">
            Мероприятия
          </NavLink>
          <NavLink className={styles.navLink} to="/news">
            Новости
          </NavLink>
        </nav>
      )}

      {isShowMenu && <BurgerMenu setShowMenu={setShowMenu} />}
    </header>
  );
};

export default Header;
