import classNames from 'classnames';
import { useContext, useEffect, useRef, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';

import { DecorIcon } from 'src/assets/icons';
import { Footer, Header } from 'src/components';
import { StateContext } from 'src/context';
import { setBrands } from 'src/store/slices/actions';

import styles from './BrandsPage.module.scss';

const BrandsPage = () => {
  const { categoryState, setCategoryState } = useContext(StateContext);

  const [category, setCategory] = useState(categoryState);
  const designers = useSelector((state) => state.slice.brands);

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const designersRef = useRef();

  useEffect(() => {
    if (!designers) {
      dispatch(setBrands());
    }
  }, []);

  useEffect(() => {
    if (categoryState != 0) designersRef.current?.scrollIntoView({ behavior: 'smooth', block: 'start' });
  }, [category, categoryState, designers]);

  return (
    <div className={styles.page}>
      <Helmet>
        <title>SibBrands - Бренды</title>
        <meta name="description" content="Одежда и аксессуары от наших дизайнеров" />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <div className={styles.title}>
          <h1>БРЕНДЫ</h1>
          <DecorIcon />
          <DecorIcon />
          <DecorIcon />
        </div>

        <div className={styles.categories}>
          <div
            onClick={() => {
              setCategory(1);
              setCategoryState(1);
            }}
            className={classNames(styles.category, { [styles.categoryActive]: category == 2 })}
          >
            ОДЕЖДА
          </div>
          <div
            onClick={() => {
              setCategory(2);
              setCategoryState(2);
            }}
            className={classNames(styles.category2, { [styles.categoryActive]: category == 1 })}
          >
            АКСЕССУАРЫ
          </div>
        </div>
        {designers && (
          <div ref={designersRef} className={styles.designers}>
            {designers
              .filter((designer) => designer.category.find((item) => item.id == category))
              .map((designer) => (
                <div key={designer.id} onClick={() => navigate(`/brands/${designer.id}`)} className={styles.designer}>
                  <img src={designer.image} />
                  <div className={styles.designerText}>
                    <p className={styles.name}>{designer.name.split('/')[0]}</p>
                    <p className={styles.brand}>{designer.name.split('/')[1]}</p>
                  </div>
                </div>
              ))}
          </div>
        )}
        <DecorIcon className={styles.decor} />
      </div>
      <Footer />
    </div>
  );
};

export default BrandsPage;
