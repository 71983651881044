import Button from '../Button';

import styles from './Modal.module.scss';

const Modal = ({ close, errorMessage }) => {
  return (
    <div className={styles.modal}>
      <div className={styles.content}>
        <p className={styles.modalTitle}>{errorMessage ? 'Ошибка!' : 'Успешно!'}</p>
        {!errorMessage ? (
          <>
            <p className={styles.modalText}>
              Скоро начнем отправлять вам
              <br /> письма, но не слишком часто ;)
            </p>
            <p className={styles.modalText}>Зайдите на почту, чтобы подтвердить</p>
          </>
        ) : (
          <p className={styles.modalText}>{errorMessage}</p>
        )}
        <Button onClick={close} className={styles.modalButton}>
          Ок
        </Button>
      </div>
    </div>
  );
};

export default Modal;
