import { createAsyncThunk } from '@reduxjs/toolkit';

import { getEvents } from 'src/API/requests';

const setEvents = createAsyncThunk('events/setEvents', async (options, thunkAPI) => {
  try {
    const response = await getEvents(options);
    return response;
  } catch (error) {
    return thunkAPI.rejectWithValue(error);
  }
});

export default setEvents;
