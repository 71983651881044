import classNames from 'classnames';
import { useEffect, useRef, useState } from 'react';
import Calendar from 'react-calendar';
import { Helmet } from 'react-helmet';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import { ArrowIcon } from 'src/assets/icons';
import { Footer, Header } from 'src/components';
import { getDate, isDateBetween, useWindowWidth } from 'src/hooks';
import { setEvents } from 'src/store/slices/actions';

import styles from './EventsPage.module.scss';
import './Calendar.scss';

const EventsPage = () => {
  const [mouseStartPosition, setMouseStartPosition] = useState();
  const [mouseEndPosition, setMouseEndPosition] = useState();
  const [index, setIndex] = useState(0);
  const [filteredEvents, setFilteredEvents] = useState();
  const [date, setDate] = useState();
  const [format, setFormat] = useState('');
  const events = useSelector((state) => state.slice.events);

  const dispatch = useDispatch();
  const slider = useRef(null);
  const navigate = useNavigate();
  const width = useWindowWidth();

  useEffect(() => {
    if (!events) {
      dispatch(setEvents());
    }
  }, []);

  useEffect(() => {
    const tmp = events?.filter((event) => {
      if (date) {
        if (isDateBetween(event.start_date, event.end_date, date)) {
          return true;
        } else {
          return false;
        }
      } else {
        return true;
      }
    });

    setFilteredEvents(
      tmp?.filter((event) => {
        if (format) {
          return event.event_format == format;
        } else {
          return true;
        }
      }),
    );
  }, [events, format, date]);

  useEffect(() => {
    slider?.current?.slickGoTo(0, true);
  }, [filteredEvents, slider]);

  const settings = {
    arrows: false,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    beforeChange: (oldIndex, newIndex) => {
      setIndex(newIndex);
    },
    afterChange: (oldIndex, newIndex) => {},
    responsive: [
      {
        breakpoint: 1100,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  return (
    <div className={styles.page}>
      <Helmet>
        <title>SibBrands - Мероприятия</title>
        <meta name="description" content="Узнай какие мероприятия будут проводиться в ближайшие дни" />
      </Helmet>
      <Header />
      <div className={styles.wrapper}>
        <h1 className={styles.title}>МЕРОПРИЯТИЯ</h1>
        <div className={styles.sliderWrapper}>
          {filteredEvents && (
            <>
              {index > 0 && (
                <button className={styles.prevArrow} onClick={() => slider?.current.slickPrev()}>
                  <ArrowIcon />
                </button>
              )}
              {width <= 1100
                ? index != filteredEvents.length - 1 && (
                    <button className={styles.nextArrow} onClick={() => slider?.current.slickNext()}>
                      <ArrowIcon />
                    </button>
                  )
                : index != filteredEvents.length - 1 ||
                  (filteredEvents.length == 3 && (
                    <button className={styles.nextArrow} onClick={() => slider?.current.slickNext()}>
                      <ArrowIcon />
                    </button>
                  ))}
              <Slider className={styles.slider} {...settings} ref={slider}>
                {filteredEvents.map((event) => (
                  <div
                    onMouseDown={(e) => setMouseStartPosition({ x: e.clientX, y: e.clientY })}
                    onMouseUp={(e) => setMouseEndPosition({ x: e.clientX, y: e.clientY })}
                    onClick={() => {
                      if (mouseEndPosition.x == mouseStartPosition.x && mouseEndPosition.y == mouseStartPosition.y)
                        navigate(`/events/${event.id}`);
                    }}
                    key={event.id}
                    className={styles.event}
                  >
                    <img className={styles.eventImage} src={event.cover} />
                    <div className={styles.eventText}>
                      <p className={styles.eventTitle}>{event.title} &#62;</p>
                      <p className={styles.eventDate}>{getDate(event)}</p>
                    </div>
                  </div>
                ))}
              </Slider>
            </>
          )}
        </div>
      </div>
      <div className={styles.calendarWrapper}>
        <div className={styles.pickFormat}>
          <p className={styles.formatText}>Формат мероприятия</p>
          <div className={styles.buttons}>
            <button
              className={classNames(styles.buttonPick, { [styles.buttonPickActive]: format == 'online' })}
              onClick={() => {
                if (!format || format == 'offline') setFormat('online');
                else setFormat('');
              }}
            >
              Онлайн
            </button>
            <button
              className={classNames(styles.buttonPick, { [styles.buttonPickActive]: format == 'offline' })}
              onClick={() => {
                if (!format || format == 'online') setFormat('offline');
                else setFormat('');
              }}
            >
              Офлайн
            </button>
          </div>
        </div>
        <Calendar
          minDetail="month"
          showNeighboringMonth={false}
          locale="ru-RU"
          next2Label={null}
          prev2Label={null}
          className={styles.calendar}
          onChange={(e) => {
            if (e.getDate() == date?.getDate()) {
              setDate(null);
            } else {
              setDate(e);
            }
          }}
          value={date}
        />
      </div>
      <Footer />
    </div>
  );
};

export default EventsPage;
