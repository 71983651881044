import { NavLink } from 'react-router-dom';

import { CloseIcon, TgIcon, VkIcon } from 'src/assets/icons';

import styles from './BurgerMenu.module.scss';

const BurgerMenu = (props) => {
  const { setShowMenu } = props;

  return (
    <div className={styles.burgerMenu}>
      <div className={styles.content}>
        <nav className={styles.nav}>
          <CloseIcon onClick={() => setShowMenu(false)} />
          <NavLink className={styles.navLink} to="/about">
            О нас
          </NavLink>
          <NavLink className={styles.navLink} to="/brands">
            Бренды
          </NavLink>
          <NavLink className={styles.navLink} to="/events">
            Мероприятия
          </NavLink>
          <NavLink className={styles.navLink} to="/news">
            Новости
          </NavLink>
        </nav>
        <div className={styles.social}>
          <a target="_blank" href="https://t.me/+faqgR6_qBkc5NmUy">
            <TgIcon />
          </a>
          <a target="_blank" href="https://vk.com/sibbrands">
            <VkIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default BurgerMenu;
