import { Route, Navigate, createRoutesFromElements, createBrowserRouter } from 'react-router-dom';

import { Paths } from 'src/constants';
import {
  MainPage,
  AboutPage,
  BrandsPage,
  EventsPage,
  NewsPage,
  BrandPage,
  EventPage,
  NewPage,
  ConfirmPage,
  UnsubscribePage,
} from 'src/pages';

const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route path={Paths.main} element={<MainPage />} />
      <Route path={Paths.about} element={<AboutPage />} />
      <Route path={Paths.brands} element={<BrandsPage />} />
      <Route path={Paths.events} element={<EventsPage />} />
      <Route path={Paths.news} element={<NewsPage />} />
      <Route path={Paths.confirm} element={<ConfirmPage />} />
      <Route path={Paths.unsubscribe} element={<UnsubscribePage />} />
      <Route path={`${Paths.brands}/:id`} element={<BrandPage />} />
      <Route path={`${Paths.events}/:id`} element={<EventPage />} />
      <Route path={`${Paths.news}/:id`} element={<NewPage />} />
      <Route path="*" element={<Navigate to={Paths.main} replace />} />
    </>,
  ),
);

export default router;
