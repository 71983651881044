import classNames from 'classnames';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { NavLink, useNavigate, useParams } from 'react-router-dom';

import { getNew, getNews, subscribe } from 'src/API/requests';
import { ArrowIcon, DecorIcon } from 'src/assets/icons';
import { Button, Footer, Header, CustomSlider, Input, Modal } from 'src/components';
import { formateDate, useWindowWidth } from 'src/hooks';
import { setNews } from 'src/store/slices/actions';

import styles from './NewPage.module.scss';

const NewPage = () => {
  const [New, setNew] = useState();
  const [email, setEmail] = useState('');
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isOpen, setIsOpen] = useState(false);
  const [isOpen2, setIsOpen2] = useState(false);
  const [isShowModal, setShowModal] = useState(false);
  const news = useSelector((state) => state.slice.news);

  const dispatch = useDispatch();
  const { id } = useParams();
  const navigate = useNavigate();
  const width = useWindowWidth();

  useEffect(() => {
    if (news) {
      setNew(news.find((New) => New.id == id));
    } else {
      dispatch(setNews());
      getNew(id).then((New) => setNew(New));
    }
  }, [id]);

  useEffect(() => {
    if (width > 1300) setIsOpen2(true);
    if (width <= 1300) setIsOpen2(false);
  }, [width]);

  const handleClick = async () => {
    if (/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/g.test(email)) {
      const res = await subscribe(email);
      if (res != 400 && res != 409) {
        setIsOpen(true);
        setEmail('');
        setError(false);
      } else if (res == 409) {
        setIsOpen(true);
        setErrorMessage('Подписка с такой почтой уже существует');
        setEmail('');
      } else if (res == 400) {
        setEmail('');
        setError(true);
      }
    } else {
      setEmail('');
      setError(true);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key == 'Enter') {
      handleClick();
    }
  };

  return (
    <div className={styles.page}>
      {isOpen && (
        <Modal
          close={() => {
            setIsOpen(false);
            setErrorMessage('');
          }}
          errorMessage={errorMessage}
        />
      )}

      <Header />
      {New && (
        <div className={styles.wrapper}>
          <DecorIcon className={styles.decor} />
          {width <= 1300 && (
            <div className={styles.navigateButtons}>
              <Button className={styles.button} onClick={() => navigate('/news')}>
                Все новости
              </Button>
              <Button className={styles.button} onClick={() => navigate('/events')}>
                Мероприятия
              </Button>
            </div>
          )}
          <div className={styles.floatLeft}>
            <div className={styles.floatRight}>
              {width > 1300 && (
                <div className={styles.navigateButtons}>
                  <Button className={styles.button} onClick={() => navigate('/news')}>
                    Все новости
                  </Button>
                  <Button className={styles.button} onClick={() => navigate('/events')}>
                    Мероприятия
                  </Button>
                </div>
              )}
              {news && (
                <div
                  className={classNames(
                    styles.newsWrapper,
                    { [styles.newsWrapperActive]: !isOpen2 },
                    { [styles.none]: isShowModal },
                  )}
                >
                  {width <= 1300 && (
                    <button className={styles.close} onClick={() => setIsOpen2(!isOpen2)}>
                      <ArrowIcon className={classNames(styles.closeIcon, { [styles.closeIconActive]: isOpen2 })} />
                    </button>
                  )}
                  {isOpen2 && (
                    <div className={styles.news}>
                      {news
                        .filter((New) => New.id != id)
                        .map((New) => (
                          <NavLink key={New.id} to={`/news/${New.id}`}>
                            <div className={styles.new}>
                              <p className={styles.newDate}>{formateDate(New.date)}</p>
                              <p className={styles.newTitle}>{New.title}</p>
                              <p className={styles.newDesc}>{New.short_description}</p>
                            </div>
                          </NavLink>
                        ))}
                    </div>
                  )}
                </div>
              )}
            </div>
            <CustomSlider
              isShowModal={isShowModal}
              setShowModal={setShowModal}
              className={styles.slider}
              classNameImage={styles.sliderImage}
              array={New.gallery}
            />
            <p className={styles.title}>{New.title}</p>
            <p className={styles.date}>{formateDate(New.date)}</p>
            <p dangerouslySetInnerHTML={{ __html: New.description }} className={styles.text}></p>
          </div>

          <div className={styles.buttons}>
            <p className={styles.subText}>Подписаться на новости</p>
            <div className={styles.subWrapper}>
              <Input
                value={email}
                onChange={(e) => setEmail(e.target.value)}
                type="email"
                placeholder={error ? 'Некорректный email' : 'email'}
                onKeyDown={handleKeyDown}
              />
              <Button onClick={handleClick}>Подписаться</Button>
            </div>
            <p className={styles.sendText}>
              Нажимая кнопку «Отправить», вы соглашаетесь на обработку персональных данных
            </p>
          </div>
        </div>
      )}
      <Footer />
    </div>
  );
};

export default NewPage;
