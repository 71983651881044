const getDate = (dateObj) => {
  const startDate = new Date(dateObj.start_date);
  const endDate = new Date(dateObj.end_date);

  const startDay = startDate.getDate();
  const endDay = endDate.getDate();
  const startMonth = getMonthName(startDate.getMonth());
  const endMonth = getMonthName(endDate.getMonth());

  if (startDate.toDateString() === endDate.toDateString()) {
    return `${startDay} ${startMonth}`;
  } else if (startDate.getMonth() === endDate.getMonth()) {
    return `${startDay}-${endDay} ${startMonth.toLowerCase()}`;
  } else {
    return `${startDay} ${startMonth}-${endDay} ${endMonth.toLowerCase()}`;
  }
};

const getMonthName = (month) => {
  const monthNames = [
    'Января',
    'Февраля',
    'Марта',
    'Апреля',
    'Мая',
    'Июня',
    'Июля',
    'Августа',
    'Сентября',
    'Октября',
    'Ноября',
    'Декабря',
  ];

  return monthNames[month];
};

export default getDate;
