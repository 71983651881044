import { createSlice } from '@reduxjs/toolkit';

import { setSlider, setNews, setBrands, setEvents } from './actions';

const initialState = {
  slider: null,
  news: null,
  brands: null,
  events: null,
  favoriteNew: {},
  newsWithoutFavoriteNew: null,
  error: {},
};

export const slice = createSlice({
  name: 'slice',
  initialState,
  reducers: {
    clearSlice: (state) => {
      state.slider = initialState.slider;
      state.news = initialState.news;
      state.brands = initialState.brands;
      state.events = initialState.events;
      state.favoriteNew = initialState.favoriteNew;
      state.newsWithoutFavoriteNew = initialState.newsWithoutFavoriteNew;
      state.error = initialState.error;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(setSlider.fulfilled, (state, action) => {
        state.slider = action.payload;
      })
      .addCase(setSlider.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(setNews.fulfilled, (state, action) => {
        state.news = action.payload;
        state.favoriteNew = action.payload.find((New) => New.favorite == true);
        state.newsWithoutFavoriteNew = action.payload.filter((New) => New.favorite != true);
      })
      .addCase(setNews.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(setBrands.fulfilled, (state, action) => {
        state.brands = action.payload;
      })
      .addCase(setBrands.rejected, (state, action) => {
        state.error = action.payload;
      })
      .addCase(setEvents.fulfilled, (state, action) => {
        state.events = action.payload;
      })
      .addCase(setEvents.rejected, (state, action) => {
        state.error = action.payload;
      });
  },
});

const { clearSlice } = slice.actions;

export { clearSlice, setSlider, setNews, setBrands, setEvents };

export default slice.reducer;
