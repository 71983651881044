import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';

import { getDesigner } from 'src/API/requests';
import { DecorIcon, InstagramIcon, TgIcon, VkIcon, WebSiteIcon, YouTubeIcon } from 'src/assets/icons';
import { Button, Footer, Header, CustomSlider } from 'src/components';
import { useWindowWidth } from 'src/hooks';

import styles from './BrandPage.module.scss';

const BrandPage = () => {
  const [designer, setDesigner] = useState();
  const [isShowModal, setShowModal] = useState(false);
  const designers = useSelector((state) => state.slice.brands);

  const { id } = useParams();
  const navigate = useNavigate();
  const width = useWindowWidth();

  useEffect(() => {
    if (designers) {
      setDesigner(designers.find((designer) => designer.id == id));
    } else {
      getDesigner(id).then((designer) => setDesigner(designer));
    }
  }, []);

  return (
    <div className={styles.page}>
      <Header />
      {designer && (
        <div className={styles.wrapper}>
          <DecorIcon className={styles.decor} />
          <div className={styles.wrapperInner}>
            <CustomSlider isShowModal={isShowModal} setShowModal={setShowModal} array={designer.gallery} />
            {width <= 1100 && (
              <p dangerouslySetInnerHTML={{ __html: designer.description }} className={styles.text}></p>
            )}
            <div className={styles.right}>
              <img className={styles.image} src={designer.image} />
              <div className={styles.about}>
                <div>
                  <p className={styles.name}>{designer.name?.split('/')[0]}</p>
                  <p className={styles.brand}>{designer.name?.split('/')[1]}</p>
                </div>
                <div className={styles.social}>
                  {designer.telegram && (
                    <a target="_blank" href={designer.telegram}>
                      <TgIcon />
                    </a>
                  )}
                  {designer.vk && (
                    <a target="_blank" href={designer.vk}>
                      <VkIcon />
                    </a>
                  )}
                  {designer.instagram && (
                    <a target="_blank" href={designer.instagram}>
                      <InstagramIcon />
                    </a>
                  )}
                  {designer.youtube && (
                    <a target="_blank" href={designer.youtube}>
                      <YouTubeIcon />
                    </a>
                  )}
                  {designer.website && (
                    <a target="_blank" href={designer.website}>
                      <WebSiteIcon />
                    </a>
                  )}
                </div>
                <Button onClick={() => navigate('/brands')} className={styles.button}>
                  Назад
                </Button>
              </div>
            </div>
          </div>
          {width > 1100 && <p dangerouslySetInnerHTML={{ __html: designer.description }} className={styles.text}></p>}
        </div>
      )}
      <Footer />
    </div>
  );
};

export default BrandPage;
