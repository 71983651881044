import { Helmet } from 'react-helmet';

import { Footer, Header } from 'src/components';

import styles from './AboutPage.module.scss';
import Mission from './Mission';

const AboutPage = () => {
  return (
    <div className={styles.page}>
      <Helmet>
        <title>SibBrands - О нас</title>
        <meta
          name="description"
          content="Это уникальная возможность пройти онлайн отборочный этап Конкурса, попасть на Межрегиональный Фестиваль моды и дизайна, получить финансовую поддержку, учиться у экспертов в индустрии, попасть на стажировку в компанию мечты и запустить собственный бизнес."
        />
      </Helmet>
      <Header />
      <Mission />
      <Footer />
    </div>
  );
};

export default AboutPage;
